export enum ChatMessageEndpoints {
    WEBSOCKET = '/messenger',
    UPLOAD_AUDIO = '/upload',
    SESSION = "/session",

    SEND_TEXT = '/upstream/messenger',
    SEND_AUDIO = '/upstream/audio',
    RECEIVE = '/user/messenger',

    APP = "/upstream",
    USER = "/user",
}

import { Injectable } from '@angular/core';
import { TimerService } from './timer.service';
import { Subject } from 'rxjs';
import { Dialog } from '../models/dialog';
import { ChatService } from './chat.service';
import { MediaType, ResponseType } from 'onevoice';

@Injectable({
  providedIn: 'root'
})
export class AudioRecorderService extends TimerService {
  public recording = false;
  public lockInput = false;
  public onSaveAudio = new Subject<Dialog>();

  private _canSave: boolean = false;
  private _mediaRecorder?: MediaRecorder;
  private _stream?: MediaStream;

  constructor(private _chatService: ChatService) {
    super();
  }

  public toggleMic(save?: boolean) {
    if (this.recording !== this.lockInput) {
      return;
    }

    this._canSave = !!save;
    !this.recording ? this.startMic() : this.stopMic();
  }

  public startMic() {
    this._recorder();
  }

  public stopMic() {
    if (this._mediaRecorder && this._stream) {
      this._stopAnimation();
      this._mediaRecorder.stop();
      this._stream.getTracks().forEach((track) => track.stop());
    }
  }

  private _playAnimation() {
    this.recording = true;
    this.lockInput = true;
    this.startTime();
  }

  private _stopAnimation() {
    this.recording = false;
    this.stopTime();
    setTimeout(() => this.lockInput = false, 1000);
  }

  private _recorder() {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(
      (stream) => this._successHandler(stream),
      (err) => alert('Não conseguimos acessar o seu microfone'));
  }

  private _successHandler(stream: MediaStream) {
    this._playAnimation();
    this._stream = stream;
    this._mediaRecorder = new MediaRecorder(stream);
    let chunks: BlobPart[] = [];

    this._mediaRecorder.ondataavailable = (data: BlobEvent) => chunks.push(data.data);
    this._mediaRecorder.onstop = () => {
      if (!this._canSave) {
        return;
      }

      const blob = new Blob(chunks, { type: 'audio/ogg' });
      const reader = new FileReader();

      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const file = new File(chunks, 'audio.webm');
        this._chatService.uploadFile(file).then(response => {
          console.log(response);
          this._sendAudio(reader.result);
        });
      };
    };

    this._mediaRecorder.start();
  }

  private _sendAudio(audio: any) {
    this.onSaveAudio.next({
      who: 0,
      message: audio,
      type: ResponseType.MEDIA,
      media: MediaType.AUDIO,
      time: new Date(),
    });
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chat-menu',
  templateUrl: './chat-menu.component.html',
  styleUrls: ['./chat-menu.component.scss']
})
export class ChatMenuComponent implements OnInit {
  @Input() data: any = {}; 
  @Output() payload = new EventEmitter();

  constructor() { }
  
  ngOnInit(): void { }

  ngAfterViewInit(): void {
    console.log(this.data);
  }

  public outputPayload(title: string, payload: string) {
    this.payload.emit({ title, payload });
  }
}

<app-chat-header *ngIf="simulator" [caller]="caller" (currentScreen)="handleScreenChange($event)"></app-chat-header>
<div class="chat" [class.simulator]="simulator">
    <div class="chat-messages" #box>
        <div *ngFor="let message of dialog; let i = index" class="message-row" [ngClass]="{
                            'me': message.who === user.id,
                            'contact': message.who !== user.id,
                            'first-of-group': isFirstMessageOfGroup(message, i),
                            'last-of-group': isLastMessageOfGroup(message, i)
                            }">

            <img *ngIf="shouldShowContactAvatar(message, i)" [src]="avatar" class="avatar">

            <ng-container
                *ngIf="message.type == ContentType.MEDIA && (message.media === MediaType.DOCUMENT || message.media === MediaType.UNKNOWN)">
                <div class="bubble">
                    <div class="message">
                        <a mat-raised-button color="secondary" [href]="message.message">Download</a>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="message.type == ContentType.MEDIA && message.media === MediaType.AUDIO">
                <div class="bubble">
                    <audio controls class="message" [src]="message.message"></audio>
                    <div class="message audio" *ngIf="message.who !== user.id">
                        <span class="audio-transcription"> Como posso te ajudar hoje? </span>
                    </div>
                    <div class="time secondary-text">{{ message.time | date:'short' }}</div>
                </div>
            </ng-container>

            <ng-container *ngIf="message.type === ContentType.MEDIA && message.media == MediaType.IMAGE">
                <div class="bubble">
                    <div class="message">
                        <img [ngClass]="{'bubble-image': true,'is-sender': message.who === user.id}"
                            [src]="sanitizer.bypassSecurityTrustResourceUrl(message.message)">
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="message.type === ContentType.MEDIA && message.media == MediaType.VIDEO">
                <div class="bubble">
                    <div class="message">
                        <img [ngClass]="{'bubble-image': true,'is-sender': message.who === user.id}"
                            src="/assets/img/video.png" (click)="openVideo(message.message)">
                    </div>
                </div>
            </ng-container>

            <!-- Text type message -->
            <ng-container *ngIf="message.type === ContentType.TEXT">
                <div class="bubble bubble-text">
                    <div class="message">
                        <ng-container *ngIf="isButtonClick(message)">
                            <small class="alabia-click">clique de botão</small>
                        </ng-container>
                        <ng-container *ngIf="!isButtonClick(message)">

                            <ng-container *ngFor="let part of parseDialog(message)">
                                <span *ngIf="!isLink(part)">{{ part }}</span>
                                <a *ngIf="isLink(part)" [href]="sanitizer.bypassSecurityTrustResourceUrl(part)">
                                    {{ part }}
                                </a>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="time secondary-text">{{ message.time | date:'short' }}</div>
                </div>
            </ng-container>

            <!-- Carousel type message -->
            <ng-container
                *ngIf="message.type === ContentType.JSON && message.message['attachment']['payload']['template_type'] === 'generic'">
                <div class="carousel">
                    <div class="bubble card-left">
                        <app-carousel (payload)="handleCarouselPayload($event)" [data]="message.message"></app-carousel>
                        <div class="time secondary-text">{{ message.time | date:'short' }}</div>
                    </div>
                </div>
            </ng-container>

            <!-- Menu type message -->
            <ng-container
                *ngIf="message.type === ContentType.JSON && message.message['attachment']['payload']['template_type'] === 'button'">
                <div class="carousel">
                    <div class="bubble card-left">
                        <app-chat-menu (payload)="handleCarouselPayload($event)" [data]="getPayload(message)">
                        </app-chat-menu>
                        <div class="time secondary-text">{{ message.time | date:'short' }}</div>
                    </div>
                </div>
            </ng-container>
        </div>

    </div>
    <small class="alabia-typing" *ngIf="typing > 0">Escrevendo... </small>

    <div class="chat-wrapper">
        <app-response-suggestion *ngIf="false" [suggestions]="sugestions" (chosen)="addSuggestion($event)">
        </app-response-suggestion>

        <div class="chat-reply">
            <form class="chat-reply__form" [ngClass]="{
            'chat-reply__form--audio-recording' : audioRecorderService.recording
        }">
                <mat-form-field appearance="standard" class="chat-reply__form-input"
                    [ngClass]="{'chat-reply__form-input--recording' : audioRecorderService.recording}">
                    <textarea [(ngModel)]="message" (keydown.enter)="send($event)" class="text-input" name="message"
                        autosize placeholder="Digite sua mensagem..." matInput [rows]="1" [autofocus]="true"
                        [ngClass]="{'text-input--lock' : audioRecorderService.lockInput}"
                        [maxRows]="audioRecorderService.lockInput ? 1 : 6" [readOnly]="audioRecorderService.lockInput"
                        #txtArea></textarea>
                </mat-form-field>

                <button class="chat-reply__form-button" mat-icon-button type="submit" aria-label="Send file"
                    (click)="uploadDialog()">

                    <svg class="chat-reply__form-icon" height="20px" width="20px" viewBox="0 0 122.88 88.98">
                        <style type="text/css">
                            .st0 {
                                fill-rule: evenodd;
                                clip-rule: evenodd;
                            }
                        </style>
                        <path class="st0" fill="#9a75fc" stroke="none"
                            d="M85.33,16.83c12.99-9.83,31.92,1.63,31.92,13.63c0,7.75-2.97,10.79-7.57,14.03 c23.2,12.41,12.7,39.86-7.54,44.49l-70.69,0c-33.2,0-45.48-44.99-10.13-55.89C14.69,6.66,66.5-17.2,85.33,16.83L85.33,16.83z M53.37,69.54V53.66H39.16l22.29-26.82l22.29,26.82H69.53v15.88H53.37L53.37,69.54z" />
                    </svg>

                </button>

                <button class="chat-reply__form-button" mat-icon-button type="submit" aria-label="Send message"
                    (click)="send()" [ngClass]="{'chat-reply__form-button--disable' : audioRecorderService.recording}">
                    <svg class="chat-reply__form-icon" height="20px" width="20px" viewBox="0 0 24 24">
                        <path d="M16.6915026,12.4744748 L3.50612381,13.2599618 C3.19218622,13.2599618 3.03521743,13.4170592
                        3.03521743,13.5741566 L1.15159189,20.0151496 C0.8376543,20.8006365 0.99,21.89 1.77946707,22.52
                        C2.41,22.99 3.50612381,23.1 4.13399899,22.8429026 L21.714504,14.0454487 C22.6563168,13.5741566
                        23.1272231,12.6315722 22.9702544,11.6889879 C22.8132856,11.0605983 22.3423792,10.4322088
                        21.714504,10.118014 L4.13399899,1.16346272 C3.34915502,0.9 2.40734225,1.00636533 1.77946707,1.4776575
                        C0.994623095,2.10604706 0.8376543,3.0486314 1.15159189,3.99121575 L3.03521743,10.4322088
                        C3.03521743,10.5893061 3.34915502,10.7464035 3.50612381,10.7464035 L16.6915026,11.5318905
                        C16.6915026,11.5318905 17.1624089,11.5318905 17.1624089,12.0031827 C17.1624089,12.4744748
                        16.6915026,12.4744748 16.6915026,12.4744748 Z" fill="#9a75fc" stroke="none"></path>
                    </svg>
                </button>
            </form>
            <div class="chat-reply__rec" [ngClass]="{'chat-reply__rec--recording' : audioRecorderService.recording}">


                <div (click)="audioRecorderService.toggleMic()" class="chat-reply__rec-mic"
                    [ngClass]="{ 'chat-reply__rec-mic--audio-recording' : audioRecorderService.recording }">
                    <span class="material-icons" *ngIf="audioRecorderService.recording">clear</span>
                    <span class="material-icons" *ngIf="!audioRecorderService.recording">mic</span>
                </div>

                <div class="chat-reply__rec-time"
                    [ngClass]="{'chat-reply__rec-time--recording': audioRecorderService.recording}">
                    {{ audioRecorderService.timer }}
                </div>

                <div (click)="audioRecorderService.toggleMic(true)" class="chat-reply__rec-done"
                    [ngClass]="{ 'chat-reply__rec-done--send' : audioRecorderService.recording }">
                    <span class="material-icons">done</span>
                </div>
            </div>
        </div>
    </div>
</div>
<app-caller [show]="caller"></app-caller>
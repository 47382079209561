import { Injectable } from '@angular/core';
import { ResponseType } from 'onevoice';
import { Dialog } from '../models/dialog';
import { Body, Response } from '../models/stomp-response';

@Injectable({
  providedIn: 'root'
})
export class HandleReceiverService {
  private translate(value: Response): Dialog {
    if (value.contentType == ResponseType.JSON) {
      try {
        return {
          who: 1,
          time: new Date(),
          message: JSON.parse(value.content.replace("\n", " ")),
          type: ResponseType.JSON,
          media: value.mediaType,
        };
      } catch (error) {
        return {
          who: 1,
          time: new Date(),
          message: value.content,
          type: ResponseType.TEXT,
          media: value.mediaType,
        };
      }

    }
    return {
      who: 1,
      time: new Date(),
      message: value.content,
      type: value.contentType,
      media: value.mediaType,
    }
  }

  public handleMessage(bodyStompResponse: Body): Dialog[] {
    return bodyStompResponse.responses.map(item => {
      return this.translate(item);
    }).filter(
      item => Boolean(item.message),
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client as Stomp, IFrame } from '@stomp/stompjs';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ChatMessageEndpoints as ENDPOINT } from '../models/chat-message-endpoints';
import { Dialog } from '../models/dialog';
import { LocalStorageKeys } from '../models/localStorage-keys';
import { CustomerService } from './customer.service';
import { HandleReceiverService } from './handle-receiver.service';
import * as SockJS from 'sockjs-client';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private url = environment.chatURL;
  private stomp?: Stomp;
  private session: string = "";

  public onReceiveMessage: Subject<Dialog> = new Subject<Dialog>();
  public OnSendFile: Subject<File> = new Subject<File>();

  private botid: number = 1;

  constructor(
    private handleReceiverService: HandleReceiverService,
    private http: HttpClient,
    private customer: CustomerService,
  ) {

  }

  public init(botid: number) {
    this.botid = botid;
    console.debug(`ChatService: received a bot ID: ${botid}`);
    this.session = this.getSessionId(botid);
    this.stomp = this._createStomp(botid);
  }

  public destroy() {
    this.session = "";
    if (this.stomp) {
      this.stomp.deactivate();
    }
  }

  private getSessionId(botID: number): string {
    let session = localStorage.getItem(LocalStorageKeys.SESSION + botID);
    if (!session) {
      return ""
    }
    return JSON.parse(session);
  }

  private setSessionId(sessionId: string): void {
    this.session = sessionId;
    localStorage.setItem(LocalStorageKeys.SESSION + this.botid, JSON.stringify(this.session));
    console.log("Update session: ", this.botid, sessionId)
  }

  private _receiveMessage(message: any) {
    try {
      const body = JSON.parse(message.body);
      this.setSessionId(body.customerChannelId);

      this.handleReceiverService.handleMessage(body).forEach(item => {
        this.onReceiveMessage.next(item);
      });

      if (window.parent) {
        window.parent.postMessage(body, '*');
      }
    } catch (error) {
      console.error(error);
    }
  }

  public uploadFile(file: File): Promise<any> {
    const data = new FormData();
    data.set('file', file);
    data.set('session', this.session);
    data.set('bot', `${this.botid}`);

    return new Promise((resolve, reject) => {
      this.http.post<any>(this.url + ENDPOINT.UPLOAD_AUDIO, data, {
        responseType: "json",
      }).subscribe(ok => {
        resolve(ok);
      }, err => {
        reject(err);
      });
    });
  }

  public sendAudioMessage(message: any) {
    const body: string = message;

    if (this.stomp) {
      this.stomp.publish({
        skipContentLengthHeader: true,
        destination: ENDPOINT.SEND_AUDIO,
        body
      });
    }
  }

  public sendTextMessage(message: string) {
    console.debug("Send:", message);
    
    let customer = this.customer.getData(this.botid);
    const body: string = JSON.stringify({
      content: message,
      firstname: customer.firstname,
      lastname: customer.lastname,
      contacts: customer.contacts,
      identifiers: customer.identifiers,
      extras: customer.extras,
    });

    const headers = { 'bot-id': `${this.botid}` };

    if (this.stomp) {
      this.stomp.publish({
        skipContentLengthHeader: true,
        destination: ENDPOINT.SEND_TEXT,
        headers,
        body
      });
    }
  }

  private _createStomp(botId: number) {
    console.debug("ChatService: create stomp");
    const stomp: Stomp = new Stomp({
      connectHeaders: {
        'bot-id': `${botId}`,
      },
      webSocketFactory: () => new SockJS(this.url + ENDPOINT.WEBSOCKET),
      onConnect: (frame: IFrame) => {
        if (!this.session) {
          setTimeout(() => this.sendTextMessage(this.customer.message));
        }
        stomp.subscribe(ENDPOINT.RECEIVE, (message) => this._receiveMessage(message));
        stomp.subscribe(ENDPOINT.USER + ENDPOINT.SESSION, (session) => this.setSessionId(session.body));
        setTimeout(() => {
          stomp.publish({
            destination: ENDPOINT.APP + ENDPOINT.SESSION + `/${botId}`,
            body: JSON.stringify(this.customer.getData(botId)),
          });
        }, 1000);
      }
    });

    stomp.activate();
    return stomp;
  }
}

<div class="menu-header">
    <p class="menu-header__text" *ngFor="let part of data['text'].split('\n')">
        {{ part }}
    </p>
</div>
<div class="menu-btn-container">
    <div *ngFor="let menu_button of data['buttons']; let first = first let last = last;">
        <button *ngIf="menu_button.type === 'postback'" class="menu-btn" [ngClass]="{first: first, last: last}"
            title="{{menu_button.payload}}" (click)="outputPayload(menu_button.title, menu_button.payload)">
            {{menu_button.title}}
        </button>
        <a *ngIf=" menu_button.type==='web_url'" class=" menu-btn-link" [ngClass]="{first: first, last: last}"
            href="{{menu_button.url}}" title="{{menu_button.payload}}" target="_blank" rel="noopener noreferrer">
            {{menu_button.title}}
        </a>
    </div>
</div>
import { Injectable } from '@angular/core';
import { TimerService } from './timer.service';

@Injectable({
  providedIn: 'root'
})
export class CallerService extends TimerService {

  constructor() {
    super();
  }

  public startCall(){
    this.startTime();
  }

  public stopCall(){
    this.stopTime();
  }

}

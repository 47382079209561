import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { ChatRoutingModule } from './chat.routing';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AutosizeModule } from 'ngx-autosize';
import { ResponseSuggestionComponent } from '../response-suggestion/response-suggestion.component';
import { CarouselComponent } from '../carousel/carousel.component';
import { CallerComponent } from '../caller/caller.component';
import { ChatHeaderComponent } from '../chat-header/chat-header.component';
import { ChatMenuComponent } from '../chat-menu/chat-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';



@NgModule({
  declarations: [
    ChatComponent,
    ResponseSuggestionComponent,
    CarouselComponent,
    CallerComponent,
    ChatHeaderComponent,
    ChatMenuComponent,
    AutofocusDirective,
    VideoPlayerComponent,
  ],
  imports: [
    AutosizeModule,
    ChatRoutingModule,
    CommonModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
  ]
})
export class ChatModule { }

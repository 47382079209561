import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ScrollDirection } from '../../models/scroll-direction';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})

export class CarouselComponent implements AfterViewInit {
  @ViewChild('carouselBox') carouselBox?: ElementRef;
  @Input() data: any = {};
  @Output() payload = new EventEmitter();

  @HostListener('window:resize')
  onResize() {
    this._canShowArrowsNavigation();
  }

  public scrollDirection = ScrollDirection;
  public showNavigation = false;
  private _x = 0;

  constructor(
    private _cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this._canShowArrowsNavigation();
    this._cdr.detectChanges();
    console.log(this.data);
  }

  private _canShowArrowsNavigation() {
    if (this.carouselBox) {
      this.showNavigation = (this.carouselBox.nativeElement.offsetWidth !== this.carouselBox.nativeElement.scrollWidth);
    }
  }

  private _allowedRange(maxWidth: number, toSum: number) {
    this._x += toSum;

    if (this._x < 0) {
      this._x = 0;
    }

    if (this._x >= (maxWidth - toSum)) {
      this._x = maxWidth - toSum
    }
    return this._x;
  }

  public scroll(direction: ScrollDirection) {
    if (!this.carouselBox) {
      return;
    }
    const width = this.carouselBox.nativeElement.offsetWidth;
    const maxWidth = this.carouselBox.nativeElement.scrollWidth;
    const sum = direction === ScrollDirection.LEFT ? -width : width;

    const x = this._allowedRange(maxWidth, sum);

    this.carouselBox.nativeElement.scrollTo(x, 0);
  }

  public outputPayload(title: string, payload: string) {
    this.payload.emit({ title, payload });
  }

  public getData(): any[] {
    return this.data["attachment"]['payload']["elements"]
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { CustomerService } from '../services/customer.service';

@Injectable({ providedIn: 'root' })
export class ParamsGuard implements CanActivate {
    constructor(private customer: CustomerService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.customer.next = state.url.split("?")[0];
        if (!this.customer.hasData(route.params.botid)) {
            console.log("Don't activate chat view");
            this.router.navigate(["customer", route.params.botid], {});
            return false;
        }

        return true;
    }
}

<div class="banner-wrapper" [style.background-image]="bannerAsURL">
    <img *ngIf="logo" class="banner-logo" [src]="logo">
    <div *ngIf="bannerText" class="banner-text" [style.color]="bannerColor">
        <p *ngFor="let line of bannerParagraph">{{ line }}</p>
    </div>
    <form class="form-wrapper">
        <mat-form-field appearance="outline">
            <mat-label>Nome</mat-label>
            <input matInput type="text" name="firstname" [(ngModel)]="data.firstname">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Sobrenome</mat-label>
            <input matInput type="text" name="lastname" [(ngModel)]="data.lastname">
        </mat-form-field>

        <mat-form-field *ngFor="let identifier of identifiers" appearance="outline">
            <mat-label>{{ identifier.toString() }}</mat-label>
            <input matInput [name]="'identifier-'+identifier.toString()" type="text"
                [(ngModel)]="data.identifiers[identifier]">
            <mat-hint *ngIf="!ValidateIdentifier(identifier, data.identifiers[identifier] || '')">
                O documento fornecido não é válido
            </mat-hint>
        </mat-form-field>

        <mat-form-field *ngFor="let contact of contacts" appearance="outline">
            <mat-label>{{ LABELS[contact] }}</mat-label>
            <input matInput [name]="'contact-'+contact.toString()" type="text" [(ngModel)]="data.contacts[contact]">
        </mat-form-field>

        <button mat-raised-button color="primary" (click)="submit()" [disabled]="!isValid()">Iniciar</button>
    </form>
</div>
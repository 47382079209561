<mat-icon class="icon arrow_left" (click)="scroll(scrollDirection.LEFT)" *ngIf="showNavigation"> keyboard_arrow_left
</mat-icon>
<div class="carousel-container">
    <div class="carousel" #carouselBox>
        <!-- Carousel Card -->
        <div *ngFor="let carousel of getData(); let first = first; let last = last" class="carousel-card"
            [ngClass]="{ first: first, last: last }">
            <div class="carousel-card-img-container">
                <img src="{{carousel.image_url}}"
                    onerror="this.src='https://www.ndays.com.br/catalog/view/theme/ndays/img/logo-ndays.png';"
                    alt="{{carousel.title}}" title="{{carousel.title}}">
            </div>
            <div class="carousel-card-details">
                <div class="carousel-card-title" title="{{carousel.title}}">
                    <p *ngFor="let part of carousel.title.split('\n')">{{ part }}</p>
                </div>
                <div class="carousel-card-description" title="{{carousel.subtitle}}">
                    <p *ngFor="let part of carousel.subtitle.split('\n')">{{ part }}</p>
                </div>
                <div class="carousel-card-btn-container">
                    <div *ngFor="let carousel_button of carousel['buttons']; let first = first let last = last;">
                        <button *ngIf="carousel_button.type === 'postback'" class="carousel-card-btn"
                            [ngClass]="{first: first, last: last}"
                            (click)="outputPayload(carousel_button.title + ': ' + carousel.title, carousel_button.payload)"
                            title="{{carousel_button.payload}}">
                            {{carousel_button.title}}
                        </button>
                        <a *ngIf="carousel_button.type === 'web_url'" class="carousel-card-btn-link"
                            [ngClass]="{first: first, last: last}" href="{{carousel_button.url}}"
                            title="{{carousel_button.payload}}" target="_blank" rel="noopener noreferrer">
                            {{carousel_button.title}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="quick-reply" *ngFor="let quick_reply of data['quick_replies'];">
        <button *ngIf="quick_reply.content_type === 'text'" class="quick-reply-item"
            (click)="outputPayload(quick_reply.title, quick_reply.payload)">{{quick_reply.title}}</button>
    </div>
</div>
<mat-icon class="icon arrow_right" (click)="scroll(scrollDirection.RIGHT)" *ngIf="showNavigation"> keyboard_arrow_right
</mat-icon>
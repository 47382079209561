<div class="caller" [class.show]="show">
    <!-- <div class="caller-details">
        <div class="client-logo">
            <img src="https://www.ndays.com.br/catalog/view/theme/ndays/img/logo-ndays.png" alt="client-logo">
        </div>
    </div> -->
    <div class="time">{{callerService.timer}}</div>

    <audio #remoteAudio autoPlay></audio>
    <audio #localAudio autoPlay></audio>
    <audio #ringtone src="assets/sounds/incoming.mp3" loop></audio>
    <audio #ringbacktone src="assets/sounds/outgoing.mp3" loop></audio>

    <div id="sip-dialpad" class="pad">
        <span class="pad__item" *ngFor="let item of phone.padItems;">
            <button (click)="phone.sendDTMF(item)" class="pad__item-digit">
                {{item}}
            </button>
        </span>
    </div>

    <button class="call_btn" [class]="isCalling ? 'active' : 'inactive'" (click)="handleCall(isCalling)">
        <mat-icon> {{ isCalling ? 'call_end' : 'call' }} </mat-icon>
    </button>
</div>

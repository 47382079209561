import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerChannelType, IdentifierType } from 'onevoice';
import { environment } from '../../environments/environment';

export interface Customer {
  firstname: string;
  lastname: string;

  contacts: {
    [key in CustomerChannelType]?: string;
  };

  identifiers: {
    [key in IdentifierType]?: string;
  };

  extras: {
    [key: string]: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private DATA_KEY: string = "customer-data";
  public next: string = "";
  public message: string = "Oi";

  constructor(private http: HttpClient) { }

  private dataKey(botid: number): string {
    return `${this.DATA_KEY}|${botid}`
  }

  public getData(botid: number): Customer {
    let content = localStorage.getItem(this.dataKey(botid));
    if (!content) {
      return {
        firstname: "",
        lastname: "",

        contacts: {},
        extras: {},
        identifiers: {},
      };
    }
    return JSON.parse(content);
  }

  public setData(botid: number, customer: Customer) {
    localStorage.setItem(this.dataKey(botid), JSON.stringify(customer));
  }

  public hasData(botid: number): boolean {
    return this.dataKey(botid) in localStorage;
  }

  public sendPushData(botid: number, notification: PushSubscription): void {
    console.log("Creating push subscription");
    this.http.post<Boolean>(`${environment.chatURL}/push/config`, {
      "bot": botid,
      "customer": this.getData(botid),
      "notification": notification,
    }).subscribe(ok => {
      console.log("Subscribed");
    });
  }
}

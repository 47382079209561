import { ElementRef, Injectable } from '@angular/core';
import { Inviter, SessionState, UserAgent } from 'sip.js';
import { Invitation, InviterInviteOptions, Registerer, Session, Transport, UserAgentState } from 'sip.js/lib/api';
import { SessionDescriptionHandler, SimpleUserDelegate, SimpleUserOptions } from "sip.js/lib/platform/web";


@Injectable({
  providedIn: 'root'
})
export class PhoneService {
  private delegate?: SimpleUserDelegate;
  private registerer?: Registerer;
  private registerRequested = false;
  private session?: Session;
  private userAgent?: UserAgent;
  private options: SimpleUserOptions = {
    reconnectionAttempts: 0,
  };
  private transport?: Transport;
  private state: UserAgentState = UserAgentState.Stopped;


  padItems = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
  private localAudio?: ElementRef;
  private remoteAudio?: ElementRef;
  private ringtone?: ElementRef;
  private ringbacktone?: ElementRef;

  constructor() {
  }


  ngOnInit() {
  }

  public isConnected(): boolean {
    if (!this.userAgent) {
      return false;
    }
    console.log("User is connected:", this.userAgent.isConnected());
    return this.userAgent.isConnected();
  }

  public setupPhone(localAudio: ElementRef,
    remoteAudio: ElementRef,
    ringbacktone: ElementRef,
    ringtone: ElementRef) {

    this.localAudio = localAudio;
    this.remoteAudio = remoteAudio;
    this.ringbacktone = ringbacktone;
    this.ringtone = ringtone;
  }
  public connetPhone() {
    this.userAgent = new UserAgent({
      uri: UserAgent.makeURI('sip:alabia-bot@100.25.197.233'),
      authorizationPassword: 'alabia-bot-2020',
      transportOptions: {
        server: "wss://100.25.197.233:8089/ws"
      },
    });
    // UserAgent's delegate
    this.userAgent.delegate = {
      // Handle connection with server established
      onConnect: (): void => {
        console.log(` Connected`);
        if (this.delegate && this.delegate.onServerConnect) {
          this.delegate.onServerConnect();
        }
        if (this.registerer && this.registerRequested) {
          console.log(`Registering...`);
          this.registerer.register().catch((e: Error) => {
            console.log(` Error occurred registering after connection with server was obtained.`);
            console.log(e.toString());
          });
        }
      }
    };

    let userAgent = this.userAgent;
    // Connect the user agent
    this.userAgent.start().then(() => {
      this.transport = userAgent.transport;
      this.transport.onDisconnect = (error?: Error): void => this.onTransportDisconnect(error);
    });
  }

  public makeCall(botid: number) {
    if (!this.isConnected()) {
      this.connetPhone();
      if (!this.isConnected()) {
        return;
      }
    }
    if (!this.userAgent) {
      return;
    }

    // Set target destination (callee)
    const target = UserAgent.makeURI("sip:" + botid + "@100.25.197.233");
    if (!target) {
      throw new Error("Failed to create target URI.");
    }

    // Create a user agent client to establish a session
    const inviter = new Inviter(this.userAgent, target, {
      sessionDescriptionHandlerOptions: {
        constraints: { audio: true, video: false }
      }
    });

    this.session = inviter;
    // Handle outgoing session state changes
    inviter.stateChange.addListener((newState) => {

      switch (newState) {
        case SessionState.Establishing:
          console.log("Ringing");
          // Session is establishing
          this.startRingbackTone();
          break;
        case SessionState.Established:
          // Session has been established
          console.log("Answered");
          this.stopRingbackTone();
          this.setupLocalMedia();
          this.setupRemoteMedia();
          break;
        case SessionState.Terminated:
          console.log("Ended");
          this.stopRingbackTone();
          this.cleanupMedia();

          this.session = undefined;

          // Session has terminated
          break;
        default:
          break;
      }
    });
    // Options including delegate to capture response messages
    var inviteOptions: InviterInviteOptions = {
      requestDelegate: {
        onAccept: (response) => {
          console.log("Positive response = ", response);
          console.log("Successfully sent INVITE");
        },
        onReject: (response) => {
          console.log("Negative response = ", response);
        }
      },
    };

    // Send initial INVITE request
    inviter.invite(inviteOptions)
      .then(() => {
        this.startRingbackTone();
        // INVITE sent
      })
      .catch((error: Error) => {
        // INVITE did not send
      });
  }

  private onTransportDisconnect(error?: Error): void {
    console.log("onTransportDisconnect", error);
    if (this.state === UserAgentState.Stopped) {
      return;
    }
    if (this.delegate && this.delegate.onServerDisconnect) {
      //     this.delegate.onDisconnect(error);
    }
    // Only attempt to reconnect if network/server dropped the connection.
    if (error && this.options.reconnectionAttempts && this.options.reconnectionAttempts > 0) {
      this.attemptReconnection();
    }
  }

  /**
 * Attempt reconnection up to `maxReconnectionAttempts` times.
 * @param reconnectionAttempt - Current attempt number.
 */
  private attemptReconnection(reconnectionAttempt = 1): void {
    if (!this.userAgent) {
      return;
    }
    const reconnectionAttempts = this.userAgent.configuration.reconnectionAttempts;
    const reconnectionDelay = this.userAgent.configuration.reconnectionDelay;

    if (reconnectionAttempt > reconnectionAttempts) {
      console.log(`Maximum reconnection attempts reached`);
      return;
    }

    if (this.transport) {
      let transport = this.transport;
      setTimeout(
        () => {
          transport.connect()
            .then(() => {
              console.log(`Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - succeeded`);
            })
            .catch((error: Error) => {
              //this.logger.error(error.message);
              console.log(`Reconnection attempt ${reconnectionAttempt} of ${reconnectionAttempts} - failed`);
              this.attemptReconnection(++reconnectionAttempt);
            });
        },
        reconnectionAttempt === 1 ? 0 : reconnectionDelay * 1000
      );
    }
  }
  /** Media constraints. */
  private get constraints(): { audio: boolean; video: boolean } {
    let constraints = { audio: true, video: false }; // default to audio only calls
    // if (this.options.media ?.constraints) {
    //   constraints = { ...this.options.media.constraints };
    //}
    return constraints;
  }
  /** Helper function to attach remote media to html elements. */
  private setupRemoteMedia(): void {
    if (!this.session) {
      throw new Error("Session does not exist.");
    }

    if (!this.remoteAudio) {
      return;
    }
    const mediaElement = this.remoteAudio.nativeElement;

    if (mediaElement) {
      const remoteStream = this.remoteMediaStream;
      if (!remoteStream) {
        throw new Error("Remote media stream undefiend.");
      }
      mediaElement.autoplay = true; // Safari hack, because you cannot call .play() from a non user action
      mediaElement.srcObject = this.remoteMediaStream;
      mediaElement.play().catch((error: Error) => {
        //   this.logger.error(`[${this.id}] Failed to play remote media`);
        //   this.logger.error(error.message);
      });
      remoteStream.onaddtrack = (): void => {
        // this.logger.log(`[${this.id}] Remote media onaddtrack`);
        mediaElement.load(); // Safari hack, as it doesn't work otheriwse
        mediaElement.play().catch((error: Error) => {
          //  this.logger.error(`[${this.id}] Failed to play remote media`);
          //  this.logger.error(error.message);
        });
      };
    }
  }
  /** The remote media stream. Undefined if call not answered. */
  get remoteMediaStream(): MediaStream | undefined {
    if (!this.session) {
      return undefined;
    }
    const sdh = this.session.sessionDescriptionHandler;
    if (!sdh) {
      return undefined;
    }
    if (!(sdh instanceof SessionDescriptionHandler)) {
      throw new Error("Session description handler not instance of web SessionDescriptionHandler");
    }
    return sdh.remoteMediaStream;
  }

  /** The local media stream. Undefined if call not answered. */
  get localMediaStream(): MediaStream | undefined {
    if (!this.session) {
      return undefined;
    }
    const sdh = this.session.sessionDescriptionHandler;
    if (!sdh) {
      return undefined;
    }
    if (!(sdh instanceof SessionDescriptionHandler)) {
      throw new Error("Session description handler not instance of web SessionDescriptionHandler");
    }
    return sdh.localMediaStream;
  }

  private setupLocalMedia(): void {
    if (!this.session) {
      throw new Error("Session does not exist.");
    }
    if (!this.localAudio) {
      return;
    }

    const mediaElement = this.localAudio.nativeElement;
    if (mediaElement) {
      const localStream = this.localMediaStream;
      console.log("localMediaStream", this.localMediaStream);
      if (!localStream) {
        throw new Error("Local media stream undefiend.");
      }
      mediaElement.srcObject = localStream;
      mediaElement.volume = 0;
      mediaElement.play().catch((error: Error) => {
        console.log(" Failed to play local media");
        console.log(error.message);
      });
    }
  }

  /** Helper function to remove media from html elements. */
  private cleanupMedia(): void {
    console.log("cleanupmedia");
    if (this.localAudio && this.localAudio.nativeElement) {
      this.localAudio.nativeElement.srcObject = null;
      this.localAudio.nativeElement.pause();
    }

    if (this.remoteAudio) {
      this.remoteAudio.nativeElement.srcObject = null;
      this.remoteAudio.nativeElement.pause();
    }
  }

  public hangup(): Promise<void> {
    console.log(`Hangup...`);
    return this.terminate();
  }

  private terminate(): Promise<void> {
    console.log(`Terminating...`);
    this.stopRingbackTone();

    if (!this.session) {
      return new Promise<void>((resolve, _) => resolve());
    }

    switch (this.session.state) {
      case SessionState.Initial:
        if (this.session instanceof Inviter) {
          return this.session.cancel().then(() => {
            console.log(` Inviter never sent INVITE (canceled)`);
          });
        } else if (this.session instanceof Invitation) {
          return this.session.reject().then(() => {
            console.log(` Invitation rejected (sent 480)`);
          });
        } else {
          throw new Error("Unknown session type.");
        }
      case SessionState.Establishing:
        if (this.session instanceof Inviter) {
          return this.session.cancel().then(() => {
            console.log(`  Inviter canceled (sent CANCEL)`);
          });
        } else if (this.session instanceof Invitation) {
          return this.session.reject().then(() => {
            console.log(`  Invitation rejected (sent 480)`);
          });
        } else {
          throw new Error("Unknown session type.");
        }
      case SessionState.Established:
        return this.session.bye().then(() => {
          console.log(` Session ended (sent BYE)`);
        });
      case SessionState.Terminating:
        break;
      case SessionState.Terminated:
        break;
      default:
        throw new Error("Unknown state");
    }

    console.log(` Terminating in state ${this.session.state}, no action taken`);
    return new Promise<void>((resolve, _) => resolve());
  }

  /**
* Send DTMF.
* @remarks
* Send an INFO request with content type application/dtmf-relay.
* @param tone - Tone to send.
*/
  public sendDTMF(tone: string): Promise<void> {
    console.log(`  sending DTMF...` + tone);

    const context = new AudioContext()
    const sampleRate = 8000
    const buffer = context.createBuffer(2, sampleRate, sampleRate)
    const data = buffer.getChannelData(0)
    const data1 = buffer.getChannelData(1)
    var currentTime = 0
    var val = tone;
    const keymaps: { [id: string]: number[] } = {
      '1': [1209, 697],
      '2': [1336, 697],
      '3': [1477, 697],
      'A': [1633, 697],
      '4': [1209, 770],
      '5': [1336, 770],
      '6': [1477, 770],
      'B': [1633, 770],
      '7': [1209, 852],
      '8': [1336, 852],
      '9': [1477, 852],
      'C': [1633, 852],
      '*': [1209, 941],
      '0': [1336, 941],
      '#': [1477, 941],
      'D': [1633, 941]
    }
    if (!(val in keymaps)) {
      return new Promise<void>((resolve, _) => resolve());
    }

    for (let i = 0; i < 0.5 * sampleRate; i++) {
      data[i] = Math.sin((2 * Math.PI) * keymaps[val][0] * (i / sampleRate))
    }

    for (let i = 0; i < 0.5 * sampleRate; i++) {
      data1[i] = Math.sin((2 * Math.PI) * keymaps[val][1] * (i / sampleRate))
    }

    const gainNode = context.createGain()
    gainNode.connect(context.destination)

    const src = context.createBufferSource()
    src.buffer = buffer
    src.connect(gainNode)
    src.start(currentTime)

    // As RFC 6086 states, sending DTMF via INFO is not standardized...
    //
    // Companies have been using INFO messages in order to transport
    // Dual-Tone Multi-Frequency (DTMF) tones.  All mechanisms are
    // proprietary and have not been standardized.
    // https://tools.ietf.org/html/rfc6086#section-2
    //
    // It is however widely supported based on this draft:
    // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00

    // Validate tone
    if (!/^[0-9A-D#*,]$/.exec(tone)) {
      console.log(new Error("Invalid DTMF tone."));
      return new Promise<void>((resolve, _) => resolve());
    }

    if (!this.session) {
      console.log(new Error("Session does not exist."));
      return new Promise<void>((resolve, _) => resolve());
    }


    // The UA MUST populate the "application/dtmf-relay" body, as defined
    // earlier, with the button pressed and the duration it was pressed
    // for.  Technically, this actually requires the INFO to be generated
    // when the user *releases* the button, however if the user has still
    // not released a button after 5 seconds, which is the maximum duration
    // supported by this mechanism, the UA should generate the INFO at that
    // time.
    // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00#section-5.3
    console.log(` Sending DTMF tone: `);
    const dtmf = tone;
    const duration = 2000;
    const body = {
      contentDisposition: "render",
      contentType: "application/dtmf-relay",
      content: "Signal=" + dtmf + "\r\nDuration=" + duration
    };
    const requestOptions = { body };

    return this.session.info({ requestOptions }).then(() => {
      return;
    });
  }


  // Sound methods
  public startRingTone() {
    try {
      if (this.ringtone) { this.ringtone.nativeElement.play(); }
    } catch (e) { }
  }
  public stopRingTone() {
    try {
      if (this.ringtone) { this.ringtone.nativeElement.pause(); }
    } catch (e) { }
  }

  public startRingbackTone() {
    try {
      if (this.ringbacktone) { this.ringbacktone.nativeElement.play(); }
    } catch (e) { }
  }

  public stopRingbackTone() {
    try {
      if (this.ringbacktone) { this.ringbacktone.nativeElement.pause(); }
    } catch (e) {
    }
  }
}

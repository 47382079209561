import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { BotService, MediaType, ResponseType } from 'onevoice';
import { RequestMediaComponent } from 'projects/one-ui/src/public-api';
import { environment } from 'projects/webchat/src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Dialog } from '../../models/dialog';
import { DialogConstructor } from '../../models/dialog-constructor';
import { LocalStorageKeys } from '../../models/localStorage-keys';
import { AudioRecorderService } from '../../services/audio-recorder.service';
import { ChatService } from '../../services/chat.service';
import { CustomerService } from '../../services/customer.service';
import { VideoPlayerComponent } from './video-player/video-player.component';

@Component({
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
  public MediaType = MediaType;
  public ContentType = ResponseType;

  private IS_SIMULATION = "isSimulation";

  // Development shortcuts
  public simulator: boolean = false;
  public caller: boolean = false;

  @ViewChild('box') box?: ElementRef;
  @ViewChild('txtArea') txtArea?: ElementRef;

  public avatar = '/assets/img/robot.png';
  public robot = { id: 1 };
  public user = { id: 0 };

  public sugestions = [
    'Como posso ajudar?',
    'Eu sou o assistente virtual da NDays, estou aprendendo a atender pessoas, como posso te ajudar?',
    'Bom dia senhor, tudo bem?',
    'Peço que aguarde um momento!',
  ];

  public dialog: Dialog[] = [];
  public message = '';

  private Scroll = new Subject<void>();
  public typing: number = 0;

  constructor(
    private bots: BotService,
    public audioRecorderService: AudioRecorderService,
    public dialogService: MatDialog,
    private _chatService: ChatService,
    private customers: CustomerService,
    private _cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private _router: Router,
    public sanitizer: DomSanitizer,
    public http: HttpClient,
    private swPush: SwPush,
    private snack: MatSnackBar,

  ) {
    this.Scroll.pipe(debounceTime(500)).subscribe((_: any) => {
      if (this.box) {
        console.log(this.box.nativeElement.scrollHeight);
        this.box.nativeElement.scrollTo(0, this.box.nativeElement.scrollHeight);
      }
    });
  }

  ngOnInit() {
    this._chatService.onReceiveMessage.subscribe((message: Dialog) => this._insert(message));
    this.audioRecorderService.onSaveAudio.subscribe((audio: Dialog) => this._insert(audio));
    this._chatService.OnSendFile.subscribe((file: File) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("loadend", () => {
        if (reader.result) {
          console.log(reader.result.toString());
          this._insert({
            who: 0,
            time: new Date(),
            type: ResponseType.MEDIA,
            media: MediaType.IMAGE,
            message: reader.result.toString(),
          });
        }
      });
    });

    this.route.queryParams.subscribe(params => {
      if (this.IS_SIMULATION in params) {
        this._handleSimulationQueryParam(params[this.IS_SIMULATION] == "true")
      }
    })
    this.route.data.subscribe(data => {

      let id = data.botid;
      this._chatService.init(id);

      this.getMessages(id).subscribe(conversations => {
        this.dialog = conversations || [];
        this.dialog.sort((d1, d2) => d1.time < d2.time ? -1 : 1)
      });

      this.bots.avatar(id, '/assets/img/robot.png').then(avatar => this.avatar = avatar);
    });
    this.fetchNotifications()
  }

  private fetchNotifications() {
    console.log("Attempting to create push notification");
    this.swPush.requestSubscription({
      serverPublicKey: environment.PushPublic,
    }).then(
      subscription => {
        console.log("Notification success");
        this.customers.sendPushData(this.route.snapshot.data.botid, subscription);
        this.snack.open("Você receberá notificações neste aparelho quando novas mensagens forem enviadas", "OK", {
          duration: 10000,
        });
      },
      err => {
        console.log("Notification not received");
        this.snack.open("Não será possível receber notificações no seu navegador", "OK");
      },
    ).catch(
      err => {
        console.log("Error during notification request");
        this.snack.open("Não será possível receber notificações no seu navegador", "OK");
      },
    ).finally(
      () => {
        console.log("Notification finished");
      },
    );

  }

  public ngAfterViewInit() {
    this._cdr.detectChanges();
    this.Scroll.next();
  }

  public ngOnDestroy(): void {
    this._chatService.destroy();
  }

  private getMessages(botId: number): Observable<Dialog[]> {
    let customer = this.customers.getData(botId);
    return this.http.post<Dialog[]>(`${environment.chatURL}/api/interactions/customer/${botId}`, customer);
  }

  private _handleSimulationQueryParam(active: boolean) {
    if (active) {
      this.simulator = true;
    } else {
      this._router.navigate([], {
        queryParams: {
          isSimulation: undefined,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  private _insert(message: Dialog) {
    if (message.who !== this.user.id) {
      console.log(message);
      this.typing += 1;

      setTimeout(() => {
        this.typing -= 1;
        this.dialog.push(message);
        this.Scroll.next();

      }, 3000);
    } else {
      this.dialog.push(message);
      this.Scroll.next();
    }
  }

  public addSuggestion(suggestion: string) {
    this.message += suggestion;
    if (this.txtArea) {
      this.txtArea.nativeElement.focus();
    }
  }

  public send(event?: Event) {
    event?.preventDefault();

    if (this.audioRecorderService.recording) {
      return;
    }

    this.message = this.message.replace(/(\r\n|\n|\r)\s+/gm, " ").trim();

    if (this.message) {
      const message = {
        who: this.user.id,
        message: this.message,
        type: ResponseType.TEXT,
        media: MediaType.UNKNOWN,
        time: new Date(),
      };
      this._insert(message);
      this._chatService.sendTextMessage(this.message);
      this.message = '';
    }
  }

  public uploadFiles(event: Event) {
    let target = event.target as HTMLInputElement;
    if (target == null || target.files == null) {
      return;
    }
    for (let i = 0; i < target.files.length; i += 1) {
      this._chatService.uploadFile(target.files[i]).then(response => {
        console.log(response);
      });
    }
  }

  public uploadDialog() {
    let reference = this.dialogService.open<RequestMediaComponent, any, boolean>(RequestMediaComponent, {
      maxWidth: "80%",
      width: "500px",
    })
    let component = reference.componentInstance;
    component.Send.subscribe((file) => {
      this._chatService.OnSendFile.next(file);
      this._chatService.uploadFile(file).then(() => {
        component.OnSend.next(true);
      }, err => component.OnSend.next(false));
    });

    reference.afterClosed().subscribe(ok => {
      console.log("Finished: ", ok);
    });
  }

  public handleCarouselPayload(payloadData: { payload: string, title: string }) {
    this._chatService.sendTextMessage(payloadData.payload);
    this.dialog.push({
      who: this.user.id,
      message: payloadData.title,
      type: ResponseType.TEXT,
      media: MediaType.UNKNOWN,
      time: new Date(),
    });
  }

  public handleScreenChange(currentScreen: boolean) {
    this.caller = currentScreen;
  }

  public isFirstMessageOfGroup(message: Dialog, i: number): boolean {
    return (i === 0 || this.dialog[i - 1] && this.dialog[i - 1].who !== message.who);
  }

  public isLastMessageOfGroup(message: Dialog, i: number): boolean {
    return (i === this.dialog.length - 1 || this.dialog[i + 1] && this.dialog[i + 1].who !== message.who);
  }

  public shouldShowContactAvatar(message: Dialog, i: number): boolean {
    return (
      message.who === this.robot.id &&
      ((this.dialog[i + 1] && this.dialog[i + 1].who !== this.robot.id) || !this.dialog[i + 1])
    );
  }


  public regex = /(https?:\/\/[^ "]+)/

  public isLink(data: string) {
    return data.match(this.regex);
  }

  public parseDialog(message: Dialog) {
    return message.message.split(this.regex);
  }

  public getPayload(message: Dialog) {
    return message.message['attachment']['payload'];
  }

  public openVideo(video: string): void {
    this.dialogService.open<VideoPlayerComponent, string, void>(VideoPlayerComponent, {
      data: video,
      maxWidth: "90vw",
      maxHeight: "90vh",
    });
  }

  public isButtonClick(message: Dialog) {
    return message.type == ResponseType.TEXT && (message.message).startsWith("#");
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'phone-phone',
  template: `
    <p>
      phone works!
    </p>
  `,
  styles: [
  ]
})
export class PhoneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { ParamsGuard } from './guards/params.guard';
import { SelectedBotResolver } from './selected-bot.resolver';
import { ChatModule } from './views/chat/chat.module';
import { CustomerComponent } from './views/customer/customer.component';
import { IndexMessageComponent } from './views/index-message/index-message.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: IndexMessageComponent
  },

  {
    path: 'chat/:botid',
    loadChildren: () => ChatModule,
    canActivate: [ParamsGuard],
    resolve: { botid: SelectedBotResolver },
  },
  {
    path: 'customer/:botid',
    component: CustomerComponent,
    resolve: { botid: SelectedBotResolver },
  }
];

const config: ExtraOptions = {
  urlUpdateStrategy: 'eager',
  useHash: true,
};


@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

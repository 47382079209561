import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { BotService, CustomerChannelType, IdentifierType, ValidateIdentifier } from 'onevoice';
import { environment } from 'projects/webchat/src/environments/environment';
import { Customer, CustomerService } from '../../services/customer.service';


@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  contacts: CustomerChannelType[] = [];
  identifiers: IdentifierType[] = [];
  public banner: string = "";
  public logo: string = "";
  public color: string = "red";
  public bannerText: string = "";
  public bannerColor: string = "white";

  get bannerAsURL(): string {
    if (this.banner.length > 0) {
      return `url("${this.banner}")`
    }
    return "";
  }

  get bannerParagraph(): string[] {
    return this.bannerText.split("\n");
  }

  public LABELS: any = {
    [CustomerChannelType.WHATSAPP]: "Whatsapp",
    [CustomerChannelType.MOBILE]: "Celular",
    [CustomerChannelType.EMAIL]: "Endereço de e-mail",
  }

  public data: Customer = {
    firstname: "",
    lastname: "",

    contacts: {},
    extras: {},
    identifiers: {},
  };

  constructor(
    public customer: CustomerService,
    private route: ActivatedRoute,
    private router: Router,
    private bots: BotService,
    private swPush: SwPush,
  ) {
    this.route.data.subscribe(data => {
      bots.publicInfo(data.botid).subscribe(info => {
        this.identifiers = info.identifiers;
        this.contacts = info.contacts;
        this.bannerText = info.bannerText;
        this.bannerColor = info.bannerColor;
      })
      if (customer.hasData(data.botid)) {
        this.data = customer.getData(data.botid);
      }
      bots.banner(data.botid, "").then(banner => {
        console.log(banner);
        this.banner = banner;
      });
      bots.logo(data.botid, "").then(url => {
        console.log(url);
        this.logo = url;
      })
    });
  }

  ngOnInit(): void {
  }

  public isValid() {
    for (const identifier of this.identifiers) {
      let value = this.data.identifiers[identifier]
      if (value === undefined || !ValidateIdentifier(identifier, value)) {
        return false;
      }
    }
    return Object.values(this.data.contacts).filter(value => value && value.length > 0).length > 0 ||
      Object.values(this.data.identifiers).filter(value => value && value.length > 0).length > 0;
  }

  ValidateIdentifier = ValidateIdentifier;

  public submit() {
    this.customer.setData(this.route.snapshot.data.botid, this.data);

    console.log(this.customer.next);
    this.router.navigate([this.customer.next]);
  }
}

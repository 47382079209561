import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CallerService } from '../../services/caller.service';

@Component({
  selector: 'app-chat-header',
  templateUrl: './chat-header.component.html',
  styleUrls: ['./chat-header.component.scss']
})
export class ChatHeaderComponent implements OnInit {
  @Input() caller?: boolean;
  @Output() currentScreen = new EventEmitter();

  constructor(
    public callerService: CallerService,
  ) { }

  ngOnInit(): void {
  }

  public changeScreen() {
    this.caller = !this.caller;
    this.currentScreen.emit(this.caller);
  }

}

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Resolve,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedBotResolver implements Resolve<number> {
  public BOT_PARAM: string = "botid";
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<number> {
    if (!route.paramMap.has(this.BOT_PARAM)) {
      return of(1);
    }

    let id = route.paramMap.get(this.BOT_PARAM);
    return of(Number(id));
  }
}

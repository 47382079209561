import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ScrollDirection } from '../../models/scroll-direction';

@Component({
  selector: 'app-response-suggestion',
  templateUrl: './response-suggestion.component.html',
  styleUrls: ['./response-suggestion.component.scss']
})
export class ResponseSuggestionComponent implements AfterViewInit {
  @Input() suggestions: string[] = [];
  @Output() chosen = new EventEmitter();

  @ViewChild('box') box?: ElementRef;

  public scrollDirection = ScrollDirection;
  public showNavigation = false;
  private _x = 0;

  constructor(private _cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
    if (this.box) {
      this.showNavigation = (this.box.nativeElement.offsetWidth !== this.box.nativeElement.scrollWidth);
    }
    this._cdr.detectChanges();
  }

  private _allowedRange(maxWidth: number, toSum: number) {
    this._x += toSum;

    if (this._x < 0) {
      this._x = 0;
    }

    if (this._x >= (maxWidth - toSum)) {
      this._x = maxWidth - toSum
    }

    return this._x;
  }

  public scroll(direction: ScrollDirection) {
    if (this.box) {
      const width = this.box.nativeElement.offsetWidth;
      const maxWidth = this.box.nativeElement.scrollWidth;
      const sum = direction === ScrollDirection.LEFT ? -width : width;

      const x = this._allowedRange(maxWidth, sum);

      this.box.nativeElement.scrollTo(x, 0);
    }
  }

  public output(suggestion: string) {
    this.chosen.emit(suggestion)
  }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  public timer = '00:00';

  private _cron?: NodeJS.Timeout;
  private _mm = 0;
  private _ss = 0;

  public startTime() {
    this._cron = setInterval(() => this._timeHandler(), 1000);
  }

  public stopTime() {
    if (this._cron) {
      clearInterval(this._cron);
      this._mm = 0;
      this._ss = 0;
      this.timer = '00:00';
    }
  }

  private _timeHandler() {
    if (this._ss < 59) {
      this._ss++;
    } else {
      this._ss = 0;
      this._mm++;
    }

    const mm = this._mm < 10 ? `0${this._mm}` : this._mm;
    const ss = this._ss < 10 ? `0${this._ss}` : this._ss;

    this.timer = `${mm}:${ss}`;
  }
}

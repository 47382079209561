import { Component } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { BotService, PublicBot } from 'onevoice';
import { CustomerService } from './services/customer.service';


const customerFields = [
  "firstname",
  "lastname",
  "contacts",
  "identifiers",
  "extras",
];

@Component({
  selector: 'webchat-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'webchat';
  bot?: PublicBot;

  constructor(
    private router: Router,
    private customer: CustomerService,
    public bots: BotService,
  ) {
    console.log("AppComponent: contructor");
    router.events.subscribe(event => {
      console.log("AppComponent: router event", event);
      if (event instanceof ActivationStart) {
        let botid = event.snapshot.params.botid;
        if (botid <= 0) {
          return;
        }
        this.bots.publicInfo(botid).subscribe(bot => {
          this.bot = bot;
          this.updateColor();
        });
        Object.entries(event.snapshot.queryParams).forEach(([field, value]) => {
          console.log(field, value);

          if (field == "message") {
            this.customer.message = value;
          } else if (Object.keys(customer.getData(botid)).indexOf(field) >= 0) {
            console.log("single", field, value);
            customer.setData(botid, {
              ...customer.getData(botid),
              [field]: event.snapshot.queryParams[field]
            });
          } else {
            let [cf, part] = field.split(".", 2);
            console.log("dual", cf, part, value);
            if (Object.keys(customer.getData(botid)).indexOf(cf) >= 0) {
              console.log("set", cf, part, value);
              customer.setData(botid, {
                ...customer.getData(botid),
                [cf]: {
                  ...(customer.getData(botid) as any)[cf],
                  [part]: value,
                },
              })
            } else {
              console.log("not in", cf, part);
            }
          }
          console.log(customer.getData(botid));
        });
      }

      this.updateColor();
    });
  }

  private updateColor() {
    if (this.bot) {
      console.log(`AppComponent: use color? ${this.bot.background}`);
      let style = document.createElement("style");
      style.appendChild(document.createTextNode(`:root { 
        --foreground: ${this.bot.background} ; 
        --foreground-dark: ${this.bot.background} ; 
        --foreground-lighter: ${this.bot.background} ; 
        --background-me: ${this.bot.background} ;
        --foreground-me: ${this.bot.foreground} ;

        --background2: ${this.bot.background2} ;
      }`));
      console.log("Appended");
      document.head.appendChild(style);
    }
  }
}

import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { PhoneService } from 'phone';
import { CallerService } from '../../services/caller.service';

@Component({
  selector: 'app-caller',
  templateUrl: './caller.component.html',
  styleUrls: ['./caller.component.scss']
})
export class CallerComponent implements AfterViewInit {
  public phone = new PhoneService();
  public isCalling: boolean = false;

  @Input() show: boolean = false;
  @ViewChild('localAudio', { static: true }) localAudio?: ElementRef;
  @ViewChild('remoteAudio', { static: true }) remoteAudio?: ElementRef;
  @ViewChild('ringtone', { static: true }) ringtone?: ElementRef;
  @ViewChild('ringbacktone', { static: true }) ringbacktone?: ElementRef;

  constructor(
    public callerService: CallerService,
    private route: ActivatedRoute,
  ) {
    this.phone.connetPhone();
  }

  ngAfterViewInit() {
    if (this.localAudio && this.remoteAudio && this.ringbacktone && this.ringtone) {
      this.phone.setupPhone(this.localAudio, this.remoteAudio, this.ringbacktone, this.ringtone);
    }
  }

  public handleCall(isCalling: boolean) {
    isCalling ? this._stopCall() : this._startCall();
  }

  private _startCall() {
    this.isCalling = true;
    this.callerService.startCall();
    this.phone.makeCall(this.route.snapshot.data.botid);
  }

  private _stopCall() {
    this.isCalling = false;
    this.callerService.stopCall();
    this.phone.hangup();
  }
}
